import React from 'react';
import { graphql } from 'gatsby';
import Article from '../components/article';

const ArticleTemplate = ({ data }) => {
  return <Article {...data.project} />;
};

export const projectQuery = graphql`
  query($slug: String!) {
    project: datoCmsArticle(slug: { eq: $slug }) {
      ...DatoArticle
    }
  }
`;

export default ArticleTemplate;
