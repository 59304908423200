import { Link } from "gatsby"
import React from "react"
import "./articleHeader.scss"

const ArticleHeader = () => {
  return (
    <header className="articleHeader">
      <div>
        {" "}
        <h1>
          <Link to="/">VIIVAN ALLE MUSTAA</Link>
        </h1>
        <h2>
          Johda ihmistä, johdat tulosta. <br /> <b>Ja nautit elämästä.</b>
        </h2>
      </div>
    </header>
  )
}
export default ArticleHeader
