import React from "react"
import { StructuredText } from "react-datocms"
import ImageSection from "./imageSection"
import VideoSection from "./videoSection"

const ArticleContent = ({ content }) => {
  return (
    <div>
      <StructuredText
        data={content}
        renderInlineRecord={({ record }) => {
          switch (record.__typename) {
            case "DatoCmsArticle":
              return <a href={`/${record.slug}`}>{record.title}</a>
            default:
              return null
          }
        }}
        renderLinkToRecord={({ record, children }) => {
          switch (record.__typename) {
            case "DatoCmsArticle":
              return <a href={`/${record.slug}`}>{children}</a>
            default:
              return null
          }
        }}
        renderBlock={({ record }) => {
          switch (record.__typename) {
            case "DatoCmsImageSection":
              return <ImageSection {...record} />
            case "DatoCmsVideoSection":
              return <VideoSection {...record} />
            default:
              return null
          }
        }}
      />
    </div>
  )
}

export default ArticleContent
