import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ArticleCard from "./articleCard"
import "./featuredArticles.scss"

const FeaturedArticles = props => {
  const data = useStaticQuery(graphql`
    query featuredArticles {
      allDatoCmsArticle(limit: 4, sort: { fields: date, order: DESC }) {
        edges {
          node {
            id
            title
            slug
            date
            image {
              fixed(width: 400) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const list = data.allDatoCmsArticle.edges
  const filteredList = list.filter(item => item.node.slug != props.slug) // filter id slugs match
  const featuredArticles = filteredList.slice(0, 3)

  return (
    <div className="featuredArticles">
      {featuredArticles?.map((article, i) => (
        <ArticleCard key={i} {...article.node} />
      ))}
    </div>
  )
}

export default FeaturedArticles
