import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF"
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn"
import { FaRegEnvelope } from "@react-icons/all-files/fa/FaRegEnvelope"
import { FaShareAlt } from "@react-icons/all-files/fa/FaShareAlt"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp"
import React from "react"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import "./share.scss"

const Share = ({ slug, title }) => {
  const isBrowser = () => typeof window !== "undefined"
  const share = () => {
    navigator
      .share({
        url: `https://viivanallemustaa.fi/#${slug}`,
        title: `Viivan alle mustaa - ${title}`,
      })
      .catch(err => console.error("Error while sharing", err))
  }
  // use web share API if we are in browser and if it is awailable and only on mobile-devices
  if (
    isBrowser() &&
    navigator?.share &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return (
      <div className="share">
        <button className="shareButton" onClick={share} type="button">
          <FaShareAlt size={30} color="#006bdc" />
        </button>
      </div>
    )
  }
  return (
    <div className="share">
      <div className="links">
        <LinkedinShareButton url={`https://viivanallemustaa.fi/${slug}`}>
          <FaLinkedinIn size={30} color="#006bdc" />
        </LinkedinShareButton>
        <TwitterShareButton url={`https://viivanallemustaa.fi/${slug}`}>
          <FaTwitter size={30} color="#006bdc" />
        </TwitterShareButton>
        <FacebookShareButton url={`https://viivanallemustaa.fi/${slug}`}>
          <FaFacebookF size={30} color="#006bdc" />
        </FacebookShareButton>
        <WhatsappShareButton
          url={`https://viivanallemustaa.fi/${slug}`}
          separator=":: "
        >
          <FaWhatsapp size={30} color="#006bdc" />
        </WhatsappShareButton>
        <EmailShareButton url={`https://viivanallemustaa.fi/${slug}`}>
          <FaRegEnvelope size={30} color="#006bdc" />
        </EmailShareButton>
      </div>
    </div>
  )
}

export default Share
