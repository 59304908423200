import { graphql } from "gatsby"
import React from "react"
import "./videoSection.scss"
const VideoSection = props => {
  let video
  console.log(props.videoUrl)

  //console.log("Video: ", props)
  const w = 640
  const h = 360
  const ar = w / h

  let arClass = "ar16x9"
  if (ar < 1.7) arClass = "ar4x3"
  if (ar < 1.3) arClass = "ar1x1"
  if (ar < 1) arClass = "ar9x16"

  switch (props.videoUrl.provider) {
    case "youtube":
      video = (
        <iframe
          title={props.videoUrl.title}
          src={`https://www.youtube.com/embed/${props.videoUrl.providerUid}`}
          width={w}
          height={h}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowFullScreen
        ></iframe>
      )
      break
    case "vimeo":
      video = (
        <iframe
          title={props.videoUrl.title}
          src={`https://player.vimeo.com/video/${props.videoUrl.providerUid}`}
          width={w}
          height={h}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      )
      break
    default:
      console.warn(`Unhandled VideoEmbed provider: ${props.videoUrl.provider}`)
  }

  return (
    <div className="videoSection">
      <div className={`videoContainer ${arClass}`}>{video}</div>
    </div>
  )
}

export default VideoSection

export const query = graphql`
  fragment DatoVideoSection on DatoCmsVideoSection {
    id: originalId
    videoUrl {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
  }
`
