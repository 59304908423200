import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import "./article.scss"
import ArticleContent from "./articleContent"
import ArticleHeader from "./articleHeader"
import ContentBlock from "./contentBlock"
import FeaturedArticles from "./featuredArticles"
import Share from "./share"

const Article = props => {
  const tags = props.tags?.split(",")
  return (
    <>
      <ArticleHeader />
      <Helmet>
        <title>{props.title}</title>
        <meta
          name="description"
          content={
            props.summary
              ? props.summary
              : "Johda ihmistä, johdat tulosta. Ja nautit elämästä."
          }
        />
        <meta name="author" content="Jori Kurttila, Markku Hugg" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content={props.title} />
        <meta
          property="og:description"
          content={
            props.summary
              ? props.summary
              : "Johda ihmistä, johdat tulosta. Ja nautit elämästä."
          }
        />
        <meta property="og:image" content={props.image.fixed.src} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:site_name" content="Viivan alle mustaa" />
      </Helmet>

      <article className="article">
        <div className="breadcrumb">
          <a href="/">Etusivu </a> <span> | {props.title} </span>
        </div>
        <img className="hero" src={props.image.fixed.src} alt={props.title} />
        <p className="date">
          Julkaistu{" "}
          <time dateTime={props.date}>
            {new Date(props.date).toLocaleDateString("fi-FI")}
          </time>
        </p>
        <h1 id={props.slug}>{props.title}</h1>
        <ArticleContent content={props.content} />
        {props.contentBlock && <ContentBlock content={props.contentBlock} />}
        {props.tags && (
          <div className="keywords">
            {tags.map((tag, i) => (
              <div key={i}>#{tag.trim()} </div>
            ))}
          </div>
        )}

        <Share slug={props.slug} title={props.title} />
        <FeaturedArticles slug={props.slug} />
        <a className="button" href="/">
          Takaisin etusivulle
        </a>
      </article>
    </>
  )
}

export default Article

export const query = graphql`
  fragment DatoArticle on DatoCmsArticle {
    id
    title
    date
    slug
    summary
    image {
      fixed(width: 700) {
        src
      }
      title
      alt
    }
    contentBlock {
      title
      productTitle
      productDescription
      buttonUrl
      buttonTitle
      iconSections {
        description
        title
        icon {
          url
        }
      }
    }
    tags
    content {
      value
      blocks {
        __typename
        ... on DatoCmsImageSection {
          ...DatoImageSection
        }
        ... on DatoCmsVideoSection {
          ...DatoVideoSection
        }
      }

      links {
        __typename
        ... on DatoCmsArticle {
          id: originalId
          title
          slug
        }
      }
    }
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
  }
`
