import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import React from "react"
import { StructuredText } from "react-datocms"
import "./imageSection.scss"

const ImageSection = ({ layout, image, text }) => {
  const className =
    layout === "teksti-kuva 50/50"
      ? "text-img"
      : layout === "kuva-teksti 50/50"
      ? "img-text"
      : layout === "kuva 50"
      ? "img-small"
      : ""
  return (
    <div className={`imageSection ${className}`}>
      <figure>
        <GatsbyImage fluid={image.fluid} alt={image.alt} />
        <figcaption>{image.title}</figcaption>
      </figure>
      <div className="textContainer">
        <StructuredText data={text} />
      </div>
    </div>
  )
}

export default ImageSection

export const query = graphql`
  fragment DatoImageSection on DatoCmsImageSection {
    id: originalId
    layout
    image {
      alt
      title
      fluid(maxWidth: 1500) {
        ...GatsbyDatoCmsFluid
      }
    }
    text {
      value
    }
  }
`
